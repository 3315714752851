import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
    <iframe width="100%" height="500" src="https://www.youtube.com/embed/Jsw14Qx13l8?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0" frameborder="0" allowfullscreen></iframe>
<br/>
<br/>
<br/>
<br/>
      <SectionTitle>Les fonctionnalités</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Charger mon support</FeatureTitle>
          <FeatureText>
            Rechargez votre support Cashless en ligne via CB, Paiement Mobile ou
            Cash.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Suivre mes transactions</FeatureTitle>
          <FeatureText>
            Toutes vos transactions listés dans votre espace personnel.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Consulter mon solde</FeatureTitle>
          <FeatureText>Consulter votre solde en temps réel</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Bloquer mon support</FeatureTitle>
          <FeatureText>
            Support perdu, égaré, oublié? vous pouvez le désactiver
            momentanément.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Me faire rembourser</FeatureTitle>
          <FeatureText>
            Faire une demande de remboursement du solde restant.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Gérer tous mes supports</FeatureTitle>
          <FeatureText>
            Une vue globale de tous vos supports Cashless.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
